/**
 * ADMIN ROUTES
 */
export const INSIGHTS_PATH = '/insights';
export const MAP_INSIGHTS_PATH = '/map';
export const OVERVIEW_INSIGHTS_PATH = 'overview';
export const INSIGHTS_PERFORMANCE_PATH = 'performance';
export const INSIGHTS_ENGAGEMENT_PATH = 'engagement';
export const REVIEW_INSIGHTS_PATH = 'review_insights';
export const GBP_INSIGHTS_PATH = 'gbp';
export const PBI_INSIGHTS_PATH = 'pbi';

export const APPLICANT_PATH = '/recruit/applicants';

export const CHECKIN_PATH = '/content/proofs';
export const CALENDAR_PATH = '/content/calendar';
export const SCHEDULER_PATH = '/content/scheduler';
export const REVIEW_PATH = '/content/reviews';
export const GALLERY_PATH = '/content/gallery';

export const JOBS_PATH = '/jobs';
export const JOBS_ALL_PATH = `${JOBS_PATH}/all`;
export const JOBS_PENDING_PATH = `${JOBS_PATH}/pending`;
export const JOBS_IN_PROGRESS_PATH = `${JOBS_PATH}/in-progress`;
export const JOBS_COMPLETED_PATH = `${JOBS_PATH}/completed`;
export const JOBS_PUBLISHED_PATH = `${JOBS_PATH}/published`;
export const JOBS_CLOSED_PATH = `${JOBS_PATH}/closed`;
export const JOBS_ARCHIVED_PATH = `${JOBS_PATH}/archived`;
export const JOBS_SHOWCASE_PATH = `${JOBS_PATH}/showcases`;

export const SETUP_SETTINGS_PATH = '/settings';
export const SETUP_USERS_PATH = '/settings/users';
export const SETUP_INTEGRATIONS_PATH = '/settings/integrations';
export const SETUP_TARGETING_PATH = '/settings/targeting';

/**
 * SALES TOOL ROUTES
 */
export const PORTFOLIO_PATH = '/portfolio';

/**
 * ACCOUNT GROUP ROUTES
 */
export const ACCOUNT_GROUP_PATH = '/group';

/**
 * SUPER ADMIN ROUTES
 */
export const SYSTEM_PATH = '/system';
export const SYSTEM_MANAGE_PATH = `${SYSTEM_PATH}/manage`;
export const SYSTEM_REVIEWS_PATH = `${SYSTEM_PATH}/reviews`;
export const SYSTEM_POSTS_PATH = `${SYSTEM_PATH}/posts`;
export const SYSTEM_GPT_PATH = `${SYSTEM_PATH}/gpt`;
export const SYSTEM_SUPERADMINS_PATH = `${SYSTEM_PATH}/superadmins`;
export const SYSTEM_USER_SEARCH_PATH = `${SYSTEM_PATH}/users`;
export const SYSTEM_MONITORING_PATH = `${SYSTEM_PATH}/monitoring`;

/**
 * Tab names
 * This is to track the name of the window to reference for tab hopping
 */
export const SYSTEM_TAB_NAME = 'System App';
export const ADMIN_TAB_NAME = 'Admin App';
