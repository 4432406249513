import React, { Suspense } from 'react';
import * as Parse from 'parse';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Themes from './themes';
import { initParseConfig } from 'store/config';
import queryClient from './store/queryClient';
import { LoadingOverlay } from 'components/Icons';
import {
    ApplicationFeature,
    ApplicationFeatureService,
} from 'utils/featureService';
import { SYSTEM_PATH } from 'ui/constants';
import { registerQueryMonitor } from 'rwl-shared/dist/utils/queryMonitor';

const AppRouter = React.lazy(() => import('./AppRouter'));

const {
    REACT_APP_PARSE_APP_ID: parseAppID,
    REACT_APP_PARSE_JS_KEY: parseJSKey,
    REACT_APP_PARSE_PORT: parsePort,
    REACT_APP_PARSE_PATH: parsePath,
    REACT_APP_PARSE_HOST: parseHost,
    NODE_ENV: nodeEnv,
    REACT_APP_MONITOR_SAMPLE_RATE: sampleRate = '0',
    REACT_APP_MONITOR_SLOW_QUERY_THRESHOLD: slowQueryThreshold = '1000',
} = process.env;

if (!parseAppID || !parseJSKey || !parseHost) {
    throw new Error(
        'RWL is not correctly configured: ' + JSON.stringify(process.env)
    );
}

Parse.initialize(parseAppID || '', parseJSKey);
Parse.User.allowCustomUserClass(true);

const portString = parsePort ? `:${parsePort}` : '';
// @ts-ignore
Parse.serverURL = `${parseHost}${portString}${parsePath || ''}`;
Parse.enableLocalDatastore();

console.log(`Using server ${Parse.serverURL}`);

const path = location.pathname;
if (path.startsWith('/_cb')) {
    // eslint-disable-next-line no-restricted-globals
    console.log('callback', location);
}

window.Parse = Parse;
(window as any).theme = Themes.default;

// enable feature service
const isDevelopment = nodeEnv === 'development';
const featureService = new ApplicationFeatureService(
    window.localStorage,
    isDevelopment
);
(window as any).APPLICATION_FEATURES = ApplicationFeature;
(window as any).features = featureService;

// Monitor parse queries
registerQueryMonitor({
    sampleRate: parseInt(sampleRate) || 0,
    slowQueryThreshold: parseInt(slowQueryThreshold) || 1000,
    parseNS: Parse,
    sendFn: (data) => Parse.Cloud.run('addQueryMonitoringData', data),
});

// make sure we have our configs before rendering the application
initParseConfig()
    .then(() => {
        const theme =
            window.location.pathname.indexOf(SYSTEM_PATH) !== -1
                ? Themes.secondary
                : Themes.default;
        ReactDOM.render(
            <React.StrictMode>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <Suspense fallback={<LoadingOverlay />}>
                            <AppRouter />
                        </Suspense>
                    </QueryClientProvider>
                </ThemeProvider>
            </React.StrictMode>,
            document.getElementById('root')
        );
    })
    .catch((err) => {
        console.info(err);
        Sentry.captureException(err);
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
